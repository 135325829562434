import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FaSearch } from 'react-icons/fa';
import { items } from "./items";


export const SearchBar = ({ isSearch = true }) => {
    const [search, setSearch] = useState('');
    const [result, setResult] = useState({})

    const navigate = (url) => {
        if (url === "backend" || url === "frontend" || url === "full_stack")
            document.querySelector("#" + url).scrollIntoView({
                behavior: "smooth",
                block: "start"
            })
        else window.location.href = url;
    }
    const handleSearch = () => {
        const keys = Object.keys(items);
        const item = keys.filter((key) => key.toLowerCase().includes(search.toLowerCase()))
        const objResult = {};
        item.forEach((key) => {
            objResult[key] = items[key];
        });
        setResult(objResult);
    }
    useEffect(() => {
        if (search !== "") handleSearch();
    }, [search])
    return (
        <>
            <div className="search-card">
                <div className="col">
                    <Form.Control className="search" id="search" type="search"
                        onChange={(e) => setSearch(e.target.value)} onBlur={() => setTimeout(() => setSearch(""), 4000)} placeholder="Rechercher une formation ou un service" />
                    <Button className="s-btn" onClick={handleSearch}>Rechercher {"   "} <FaSearch /> </Button>
                </div>
                {isSearch && search !== "" && (
                    <>
                        <div className="search-result">
                            <ul>
                                {Object.keys(result).map((res, index) => {
                                    return <li key={index} onClick={() => navigate(result[res])}>{res}</li>
                                })}
                                { Object.keys(result).length ===  0 && <li>Aucun resultat pour cette recherche</li> }
                            </ul>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

import React, { Component } from "react";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaUser, FaUserCircle } from "react-icons/fa";

const testimonials = [
  { name: "Aïcha Djité", role: "Utilisatrice inscrite", text: "Je suis vraiment satisfaite et j’espère pouvoir bénéficier de vos formations. Merci!" },
  { name: "Thierno Diaw", role: "Utilisateur inscrit", text: "C'est une bonne idée de faire cette plateforme pour nous débutants, afin d'apprendre comment créer des applications et des sites web." },
  { name: "Omar Diop", role: "Utilisateur inscrit", text: "Honneur d'intégrer votre plate-forme" },
  { name: "Papa Dieng", role: "Utilisateur inscrit", text: "Félicitations pour cette belle initiative" },
  { name: "Amadou Ndao", role: "Utilisateur inscrit", text: "J'ai bien aimé votre initiative" },
  { name: "Jules Sankaré", role: "Utilisateur inscrit", text: "J'apprécie beaucoup cette formation" },
  { name: "Cheikh Maremou Ndiaye", role: "Utilisateur inscrit", text: "Je pense améliorer mon niveau" },
  { name: "Oumy Khairy", role: "Utilisatrice inscrite", text: "Je vous déclare les meilleurs" }
];

const Testimonials = () => {
  return (
    <section className="py-5 bg-light">
      <Container className="text-center">
        <p className="text-muted">Plus de 200 personnnes qui utilisent notre plateforme.</p>
        <h2 className="fw-bold display-5">Quelques témoignages de nos utilisateurs</h2>
        
        <Button variant="dark" className="mt-4" style={{backgroundColor:"#8b121c"}}>Des contenus de qualités pour vous !</Button>

        <Row className="mt-5">
          {testimonials.map((testimonial,index) => (
            <Col md={4} key={index} className="mb-4">
              <Card className="shadow-lg border-0">
                <Card.Body>
                  <div className="d-flex justify-content-center mb-3">
                    {[...Array(1)].map((_, index) => (
                      <FaUserCircle key={index} color="#8b121c"  />
                    ))}
                  </div>
                  <Card.Text className="fst-italic">“{testimonial.text}”</Card.Text>
                  <div className="d-flex align-items-center mt-3"><div>
                      <h6 className="mb-0 fw-bold">{testimonial.name}</h6>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Testimonials;

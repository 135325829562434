import React from 'react';
import { MDBFooter, MDBContainer, MDBBtn } from 'mdb-react-ui-kit';
import { FaFacebook } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io5";
import { FaYoutubeSquare } from "react-icons/fa";
import { IoLogoTiktok } from "react-icons/io5";
import { FaLinkedin } from "react-icons/fa";


export const FooterLinks = () => {
  return (
    <>
    <br/>
    <h1 style={{ textAlign:"center", margin:"10px" }}>Suivez-nous sur les réseaux sociaux </h1>
    <MDBFooter className='bg-dark text-center text-white'>
    <MDBContainer className='p-4 pb-0'>
      <section className='mb-4'>
        <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'> <FaFacebook /> </MDBBtn>
        <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'> <IoLogoWhatsapp  /> </MDBBtn>
        <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'> <FaYoutubeSquare/> </MDBBtn>
        <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'> <IoLogoTiktok /> </MDBBtn>
        <MDBBtn outline color="light" floating className='m-1' href='#!' role='button'> <FaLinkedin /> </MDBBtn>
      </section>
      <div className='text-center p-3'> © 2024 Copyright {" "} : <a className='text-white'> informatiquesenwolof.com </a></div>
    </MDBContainer>
  </MDBFooter>    
    </>
  );
}
import React from "react";
import { getCategories } from "../api/axios.tsx";
import { useQuery } from "@tanstack/react-query";
import { Suspense , lazy } from "react";

const CourseModel = lazy(()=>import("../compoModels/model"));

export const CourseCard = () => {
  const {isLoading , error , data } = useQuery({
    queryKey: ["getCategories"],
    queryFn: getCategories,
    reloadOnWindowFocus: true,
    refetchInterval:10000,
    refetchOnWindowFocus:true,
    staleTime: 10000
  });

  const h1 = "Nos formations disponibles pour le moment";
  const  p = "Nos formations sont pour vous que vous soyez débutant ou que vous ne comprenez pas le français , vous pouvez toujours apprendre dans votre langue maternelle avec nous." 
  return ( 
      <Suspense fallback={<h1>Chargement des categories de formation....</h1>}>
        <CourseModel Categories={data} Loading={isLoading} isError= {error} TxtBtn={"Démarrer"} Title={h1} Subtitle={p}/>
      </Suspense>
   );
};

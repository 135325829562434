import React, { useState , useEffect } from "react";
import { SearchBar } from "../searchBars/searchBar";
const h2 = `Informatiques en wolof `
export const ContainerCarousel = ({ otitle = h2 }) => {
  const [isSearch,setIsSearch] =  useState(true);

  return (
    <>
      <div>
        <div className={`carousel-2`}>
          <div className="carousel-child-2">
            <div className="responsiveBody">
            <h1> {otitle}  </h1>
            <p style={{color:"white" , textAlign:"center" }}>Des <span >formations en langues locales</span> accéssibles par tous !</p>
            <div className="container" justify-content-center>
              <div className="row">
                <SearchBar isSearch={isSearch} />
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
{/***
  

  */}

import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App';
import './index.css';
import { EndPoint } from './routes/routes';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryclient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<QueryClientProvider client={queryclient}>
    <EndPoint />
</QueryClientProvider>
);

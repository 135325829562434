import axios from "axios";

const api = axios.create({
    baseURL: `https://home.informatiquesenwolof.com/api`,
    headers: {"Content-Type": "application/json"},
    withCredentials: true
});

interface Response {
    nom: string;
    nbUsers: number;
    urlImg: string;
}
interface params{
    email:string;
    fullname:string;
    number:number;
    message:string;
}

export const getCategories = async (): Promise<Response[]> => {
    try {
        const res = await api.get<{ data: Response[] }>("/getcategories");
        return res.data?.data ?? [];
    } catch (error) {
        return [{ nom: "error", nbUsers: 0, urlImg: "" }];
    }
};
export const submitFormContact  = async ({fullname,email,number,message}:params):Promise<boolean> => {
    try{
        const res = await api.post("/submit/contact",{fullname,email,number,message});
        return res.status === 200;
    }catch(error){
        return false;
    }
 }
 
 
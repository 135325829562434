import React from "react";
import { HiAcademicCap, HiUserGroup } from "react-icons/hi";
import { HiOutlineShoppingCart } from "react-icons/hi";

export const AllServices = () => {
  const handleRedirect = (link) => {
      window.location.href = `https://${link}.informatiquesenwolof.com`;
  };
  return (
    <>
      <div className="items">
        <div className="items-child" style={{flexDirection:"column"}} onClick={() => handleRedirect("uploads")}>
          <HiOutlineShoppingCart />
          <p> Vendre mes formations </p>
        </div>
        <div className="items-child" style={{flexDirection:"column"}}  onClick={() => handleRedirect("course")}>
          <HiAcademicCap />
          <p> Découvrir nos Formations </p>
        </div>
        <div className="items-child"  style={{flexDirection:"column"}}  onClick={() => handleRedirect("communauty")}>
          <HiUserGroup  />
          <p> Rejoindre notre communauté </p>
        </div>
      </div>
    </>
  );
};

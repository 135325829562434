const urlFreeCourse = "https://free-course.informatiquesenwolof.com/"
const urlCourse = "https://course.informatiquesenwolof.com/"
const urlAccount = "https://my-account.informatiquesenwolof.com/"
const urlAuthLogin = "https://auth.informatiquesenwolof.com/"
const urlAuthCreateAccount = "https://auth.informatiquesenwolof.com/"

export const items = {
    "Formation html": urlFreeCourse + "html",
    "Formation css": urlFreeCourse + "css",
    "Formation mysql": urlFreeCourse + "mysql",
    "Formation python": urlFreeCourse + "python",
    "Formation react": urlFreeCourse + "reactjs",
    "Formation nodejs": urlFreeCourse + "nodejs",
    "Formation javascript": urlFreeCourse + "javascript",

    "Formation backend ": "backend",
    "Formation frontend ": "frontend",
    "Formation full_stack ": "full_stack",

    "Ma formation  nodejs": urlCourse + "nodejs",
    "Ma formation  html": urlCourse + "html",
    "Ma formation  css": urlCourse + "css",
    "Ma formation  mysql": urlCourse + "mysql",
    "Ma formation  python": urlCourse + "python",
    "Ma formation  react": urlCourse + "reactjs",
    "Ma formation  nodejs": urlCourse + "nodejs",
    "Ma formation  javascript": urlCourse + "javascript",

    "connexion": urlAuthLogin + "login",
    "créer compte": urlAuthCreateAccount,
    "mon compte": urlAccount
}
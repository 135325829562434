import React from "react";
import { Card, Container, Col, Row, Button } from "react-bootstrap";
import { HiUserGroup } from "react-icons/hi";
import {Spinners} from '../assets/spinner';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const  CourseModel = ({ Categories, TxtBtn, Title, Subtitle , Loading , isError }) => {
  const navigate = (link) => {
    window.location.href = link;
  };

  return (
    <Container id="card-container">
      <Row className="course-row">
        <h1 style={{ textAlign: "center", margin: "20px" }}> {Title} </h1>
        <p style={{ textAlign: "center" }}> {Subtitle} </p>
        {Categories &&
          Categories[0].nom !== "error" &&
          Categories.map((category, index) => (
            <Col key={index}>
              <Card className="course-card">
                <LazyLoadImage effect="blur" loading="lazy" variant="top"  src={category.urlImg} />
                <Card.Body>
                  <Card.Title>{category.nom}</Card.Title>
                  <Card.Text>{""}</Card.Text>
                  <div className="btn-row">
                    <div className="btn-col">
                      <Button id="course-btn" onClick={() => navigate(`${category.link}`)}>
                        {" "}
                        {TxtBtn}{" "}
                      </Button>
                    </div>
                    <div className="btn-col">
                      <Button id="course-btn">{category.nbUsers} <HiUserGroup />{" "}</Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        {(isError ||  (Categories && Categories[0].nom === "error") || !Categories) &&  <p style={{ textAlign: "center" , color:"red" }}> Une erreur de chargement des formations est survenue, rechargez la page ... </p>}
        {Loading && <Spinners style={{ textAlign: "center" }}/>}
      </Row>
    </Container>
  );
};
export default CourseModel;